@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  .poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }

  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }

  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }

  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }

  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }

  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }

  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }

  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }

  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.video-box {
  position: relative;
}

.video-box .image {
  position: relative;
  overflow: hidden;
}

.video-box .image img {
  position: relative;
  width: 100%;
}

.video-box .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  line-height: 80px;
  background: rgba(19, 184, 234, 0.08);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.video-box .overlay-box:hover {
  background: rgba(0, 0, 0, 0.45);
}

.video-box .overlay-box span {
  position: relative;
  width: 80px;
  height: 80px;
  top: 40%;
  z-index: 99;
  color: #222222;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  border-radius: 50%;
  padding-left: 4px;
  background-color: #ffffff;
  display: inline-block;
  margin-top: -40px;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.video-box:hover .overlay-box span {
  transform: rotate(360deg);
}

/* Contact from */

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
  border-color: #ff0000 !important;
}

.contact-form label.error {
  display: block;
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 11px;
  color: #ff0000;
  font-weight: 500;
}

/*Contact Form*/

.contact-form {
  position: relative;
}

.contact-form .form-group {
  position: relative;
  margin-bottom: 25px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="password"],
.contact-form select,
.contact-form textarea {
  display: block;
  width: 100%;
  line-height: 28px;
  height: 50px;
  font-size: 14px;
  padding: 10px 22px;
  background: none;
  color: #000000;
  border-radius: 2px;
  border: 1px solid #d7d7d7;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.contact-form textarea {
  height: 200px;
  resize: none;
  font-size: 14px;
  background: none;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form input[type="password"]:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  border-color: #13b5ea;
}

.contact-form .theme-btn {
  margin-top: 0px;
  padding: 12px 45px;
  cursor: pointer;
}

/* btn */

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 16px 40px;
  font-weight: 700;
  overflow: hidden;
  border-radius: 2px;
  overflow: hidden;
  background-color: #13b5ea;
  text-transform: capitalize;
  font-family: "Quicksand", sans-serif;
}

.btn-style-one:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  background-color: #222222;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.2, 1);
  transform: scale(0.2, 1);
}

.btn-style-one .txt {
  position: relative;
  z-index: 1;
}

.btn-style-one:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-one:hover {
  color: #ffffff;
}

/* News block */

.news-block {
  position: relative;
  margin-bottom: 40px;
}

.news-block .inner-box {
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box .image {
  position: relative;
}

.news-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box:hover .image img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
}

.news-block .inner-box .image .category {
  position: absolute;
  left: 20px;
  bottom: 40px;
  padding: 5px 10px 2px;
  background-color: #ffffff;
}

.news-block .inner-box .image .category li {
  position: relative;
  display: inline-block;
}

.news-block .inner-box .image .category li a {
  position: relative;
  color: #13b5ea;
  font-size: 14px;
  text-transform: uppercase;
}

.news-block .inner-box .lower-content {
  position: relative;
  margin-top: -20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 30px 20px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box .lower-content .author {
  position: relative;
  color: #7c7b7b;
  font-size: 15px;
  margin-bottom: 13px;
}

.news-block .inner-box .lower-content .author .author-image {
  position: absolute;
  left: 0px;
  top: -5px;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
}

.news-block .inner-box .lower-content h5 {
  position: relative;
  color: #262626;
  font-weight: 500;
  line-height: 1.4em;
  margin-bottom: 18px;
}

.news-block .inner-box .lower-content h5 a {
  position: relative;
  color: #222222;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.news-block .inner-box .lower-content h5 a:hover {
  color: #13b5ea;
}

.news-block .inner-box .lower-content .text {
  position: relative;
  color: #222222;
  font-size: 15px;
  line-height: 1.7em;
  margin-bottom: 25px;
  font-weight: 400;
}

.news-block .inner-box .lower-content .post-date {
  position: relative;
}

.news-block .inner-box .lower-content .post-date:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  width: 100%;
  height: 1px;
  background-color: #dde2db;
}

.news-block .inner-box .lower-content .post-date li {
  position: relative;
  color: #7c7b7b;
  background-color: #ffffff;
  font-size: 14px;
  padding-right: 10px;
  line-height: 1.4em;
  margin-right: 12px;
  font-weight: 400;
  display: inline-block;
}

.news-block .inner-box .lower-content .post-date li:after {
  position: absolute;
  content: ".";
  right: -8px;
  top: -11px;
  color: #cdd5ca;
  font-size: 50px;
}

.news-block .inner-box .lower-content .post-date li:last-child::after {
  display: none;
}

.news-block .inner-box:hover .lower-content {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

/* Nes-single-page */
.blog-single {
  position: relative;
}

.blog-single .inner-box {
  position: relative;
}

.blog-single .inner-box .image {
  position: relative;
}

.blog-single .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.blog-single .inner-box .lower-content {
  position: relative;
  padding: 25px 30px;
  border: 1px solid #eeeeee;
}

.blog-single .inner-box .lower-content h6 {
  position: relative;
  font-weight: 600;
  color: #222222;
  line-height: 1.4em;
}

.blog-single .inner-box .lower-content .post-meta {
  position: relative;
  margin: 12px 0px 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f6f6f6;
}

.blog-single .inner-box .lower-content .post-meta:after {
  position: absolute;
  content: "";
  left: 0px;
  bottom: -1px;
  width: 70px;
  height: 1px;
  background-color: #13b5ea;
}

.blog-single .inner-box .lower-content .post-meta li {
  position: relative;
  color: #999999;
  font-size: 15px;
  padding-left: 22px;
  margin-right: 15px;
  display: inline-block;
}

.blog-single .inner-box .lower-content .post-meta li .icon {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  color: #13b5ea;
}

.blog-single .inner-box .lower-content .text {
  position: relative;
  margin-bottom: 30px;
}

.blog-single .inner-box .lower-content .text p {
  position: relative;
  color: #848484;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.8em;
  margin-bottom: 20px;
}

.blog-single .inner-box .lower-content .text blockquote {
  border-left: 3px solid #13b5ea;
  font-style: italic;
  margin-left: 40px;
  font-size: 16px;
  margin-top: 40px;
  padding-left: 40px;
  line-height: 1.8em;
  margin-bottom: 40px;
  font-weight: 300;
}

/* News sidebar */

.sidebar-widget {
  position: relative;
  margin-bottom: 40px;
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

.sidebar-widget .sidebar-title {
  position: relative;
}

.sidebar-widget .sidebar-title h6 {
  position: relative;
  color: #222222;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.sidebar-widget .sidebar-title h6:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 40px;
  height: 2px;
  background-color: #222222;
  cursor: pointer;
}

.popular-posts .post {
  position: relative;
  font-size: 14px;
  color: #666666;
  padding: 0px 0px;
  margin-bottom: 22px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
}

.popular-posts .post:last-child {
  margin-bottom: 0px;
}

.popular-posts .post .text {
  position: relative;
  top: -4px;
  font-size: 15px;
  margin: 0px 0px 0px;
  font-weight: 600;
  color: #242424;
  line-height: 1.6em;
  text-transform: capitalize;
  cursor: pointer;
}

.popular-posts .post .text a {
  color: #242424;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.popular-posts .post a,
.popular-posts .post a:hover {
  color: #13b5ea;
}
.popular-posts .post-info {
  font-size: 13px;
  color: #9a9a9a;
  font-weight: 500;
}

/* form case section */

.case-section {
  position: relative;
  padding: 95px 0px 0px;
  background-color: #f5f5f5;
}

.case-section.alternate {
  background-color: #ffffff;
  padding-bottom: 60px;
}

.case-block {
  position: relative;
}

.case-block .inner-box {
  position: relative;
  text-align: center;
}

.case-block .inner-box .image {
  position: relative;
}

.case-block .inner-box .image img {
  position: relative;
  width: 100%;
  display: block;
}

.case-block .inner-box .image {
  position: relative;
}

.case-block .inner-box .image .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: #ffffff;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  transition: all 700ms ease;
}

.case-block .inner-box .image .overlay-box:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  background: rgba(19, 181, 234, 0.9);
  -moz-transform: rotateX(-180deg);
  -webkit-transform: rotateX(-180deg);
  -ms-transform: rotateX(-180deg);
  -o-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.case-block .inner-box:hover .overlay-box:before {
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
  opacity: 1;
  visibility: visible;
}

.case-block .inner-box:hover .overlay-box {
  opacity: 1;
}

.case-block .inner-box .overlay-inner {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: table;
  vertical-align: middle;
  padding: 10px 30px;
}

.case-block .inner-box .overlay-inner .content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.case-block .inner-box .overlay-inner .content h3 {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.case-block .inner-box .overlay-inner .content h3 a {
  position: relative;
  color: #ffffff;
}

.case-block .inner-box .overlay-inner .content .text {
  position: relative;
  font-size: 14px;
  line-height: 1.9em;
  margin-bottom: 15px;
}

.case-block .inner-box .overlay-inner .content .read-more {
  position: relative;
  color: #ffffff;
  font-size: 16px;
}

.case-block .inner-box .overlay-inner .content .read-more .fa {
  position: relative;
  top: 1px;
  margin-left: 4px;
}

.case-section .lower-box {
  position: relative;
  padding: 50px 0px;
  text-align: center;
}

.case-section .lower-box .case-text {
  position: relative;
  color: #222222;
  font-size: 18px;
  padding: 0px 15px;
}

.case-section .lower-box .case-text a {
  position: relative;
  color: #13b5ea;
}

.case-section .owl-dots {
  display: none;
}

.case-section .owl-nav {
  position: absolute;
  left: 0px;
  bottom: -35px;
  width: 100%;
}

.case-section .owl-nav .owl-prev {
  position: absolute;
  left: 200px;
  width: 50px;
  height: 50px;
  color: #ffffff;
  font-size: 20px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  background-color: #c3c3c3;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.case-section .owl-nav .owl-next {
  position: absolute;
  right: 200px;
  width: 50px;
  height: 50px;
  color: #ffffff;
  font-size: 20px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  background-color: #c3c3c3;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.case-section .owl-nav .owl-prev:hover,
.case-section .owl-nav .owl-next:hover {
  background-color: #13b5ea;
}

/* Faq search box */

.faq-search-box {
  position: relative;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 50px;
}

.faq-search-box .form-group {
  position: relative;
  margin: 0px;
}

.faq-search-box .form-group input[type="text"],
.faq-search-box .form-group input[type="search"] {
  position: relative;
  line-height: 28px;
  color: #222222;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 60px;
  border-radius: 0px;
  padding: 15px 15px 15px 25px;
  border: 1px solid #e0e0e0;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.faq-search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 65px;
  height: 60px;
  line-height: 58px;
  text-align: center;
  display: block;
  font-size: 22px;
  color: #c0c0c0;
  background: none;
  font-weight: normal;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.faq-search-box .form-group input:focus {
  border-color: #13b5ea;
}

.faq-page-section {
  position: relative;
  padding: 80px 0px 80px;
}

.faq-page-section .auto-container {
  max-width: 1000px;
  margin: 0 auto;
}

/*Sidebar Search Form*/

.faq-search-box {
  position: relative;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 50px;
}

.faq-search-box .form-group {
  position: relative;
  margin: 0px;
}

.faq-search-box .form-group input[type="text"],
.faq-search-box .form-group input[type="search"] {
  position: relative;
  line-height: 28px;
  color: #222222;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 60px;
  border-radius: 0px;
  padding: 15px 15px 15px 25px;
  border: 1px solid #e0e0e0;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.faq-search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 65px;
  height: 60px;
  line-height: 58px;
  text-align: center;
  display: block;
  font-size: 22px;
  color: #c0c0c0;
  background: none;
  font-weight: normal;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.faq-search-box .form-group input:focus {
  border-color: #13b5ea;
}

.accordion-box {
  position: relative;
}

.accordion-box .block {
  position: relative;
  margin-bottom: 15px;
}

.accordion-box .block .acc-btn {
  position: relative;
  font-size: 16px;
  margin-bottom: 0px;
  cursor: pointer;
  line-height: 24px;
  border: 1px solid #eaeaea;
  font-weight: 700;
  padding: 15px 10px 15px 80px;
  color: #444444;
  text-transform: uppercase;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box .block .acc-btn .icon-inner {
  color: #13b5ea;
  font-size: 16px;
}

.accordion-box .block .icon-outer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 60px;
  height: 100%;
  text-align: center;
  font-size: 13px;
  color: #b3b2b2;
  background: #eaeaea;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.accordion-box .block .icon-outer .icon {
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -21px;
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 13px;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active .icon-outer {
  background-color: #153e4d;
}

.accordion-box .block .icon-outer .icon-plus {
  opacity: 1;
}

.accordion-box .block .icon-outer .icon-minus {
  opacity: 0;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-minus {
  opacity: 1;
  color: #00c0ff;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus {
  opacity: 0;
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content {
  position: relative;
  font-size: 13px;
  background: #ffffff;
  padding: 25px 0px 15px;
}

.accordion-box .block .content p {
  margin-bottom: 20px;
  line-height: 1.8em;
  color: #777777;
  font-size: 15px;
}

.accordion-box .block .content p:last-child {
  margin-bottom: 0px;
}
.faq-search-box input[type="text"],
.faq-search-box input[type="search"] {
  position: relative;
  line-height: 28px;
  color: #222222;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 60px;
  border-radius: 0px;
  padding: 15px 15px 15px 25px;
  border: 1px solid #e0e0e0;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.faq-search-box input:focus {
  border-color: #13b5ea;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* testimonial css */

.testimonial-block-two {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-block-two .inner-box {
  position: relative;
  border-radius: 4px;
  padding: 30px 30px 40px;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
}

.testimonial-block-two .inner-box .text {
  position: relative;
  color: #797979;
  font-size: 16px;
  line-height: 1.8em;
  margin-bottom: 24px;
}

.testimonial-block-two .inner-box .author-box {
  position: relative;
  margin-bottom: 0px;
}

.testimonial-block-two .inner-box .author-box .inner {
  position: relative;
  padding-left: 90px;
  padding-top: 8px;
  min-height: 68px;
}

.testimonial-block-two .inner-box .author-box .inner .image {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 68px;
  height: 68px;
  overflow: hidden;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .author-box .inner h5 {
  position: relative;
  font-weight: 600;
  color: #222328;
  line-height: 1.2em;
  margin-bottom: 6px;
}

.testimonial-block-two .inner-box .author-box .inner .designation {
  position: relative;
  color: #13b5ea;
  font-size: 14px;
  font-style: italic;
}

.register-section {
  position: relative;
  z-index: 9;
  background-color: #ffffff;
  padding: 60px 0px 40px;
}

.register-section .form-column {
  margin-bottom: 40px;
}

.register-section .form-column .sec-title {
  position: relative;
  margin-bottom: 30px;
}

.register-section .form-column .sec-title h2 {
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(./assets/preloader.svg);
  background-size: 80px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
